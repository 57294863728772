import React from 'react'
import styled from 'styled-components/macro';

import SidebarContainer from '../../Sidebar/Container';
import PageSidebarContainer from '../../PageSidebar/Container';

import { Route } from 'react-router-dom';


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;

const LeftSide = styled.main`
  flex: 1;
  min-width: 0;
  height: auto;
  color: ${props => props.theme.normalText};
  padding:20px;

  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  border-radius: 2px;

  @media (max-width: 768px) {
    display: none;
  }
`;


const Press = () => {

  return (
    <Wrapper>
      <LeftSide>

        <h2>Press</h2><br/>
        <p>Please feel free to contact us for any press or media inquiries. We would be happy to answer any questions.</p>
        <p>Press/media inquiries: contact@pabble.co</p>

      </LeftSide>

      <SidebarWrapper>

        <Route component={SidebarContainer} />

        <Route component={PageSidebarContainer} />

      </SidebarWrapper>
    </Wrapper>
  )
};

export default Press;
