import React from 'react';
import styled from 'styled-components/macro';
import NavLink from '../../shared/NavLink';

const Item = styled(NavLink)`
  padding: 3px 15px;
  font-size: 13px;
  text-decoration: none;
  color: ${props => props.theme.normalText};
  width:50%;

`;

const SideBarPageItem = ({ page }) => {
  // const isAll = page === 'all';
  return (
    <Item exact={false} to={`/${page}`}>
      {page}
    </Item>
  );
};

export default SideBarPageItem;
