import React from 'react'
import styled from 'styled-components/macro';

import SidebarContainer from '../../Sidebar/Container';
import PageSidebarContainer from '../../PageSidebar/Container';
import AboutUs from '../../../assets/about-us.png'

import { Route } from 'react-router-dom';


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;

const LeftSide = styled.main`
  flex: 1;
  min-width: 0;
  height: auto;
  color: ${props => props.theme.normalText};
  padding:20px;

  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  border-radius: 2px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const AboutImage = styled.img`
  width: 100%;
`;

const About = () => {

  return (
    <Wrapper>
      <LeftSide>

        <h2>About Us</h2><br/>
        <AboutImage src={AboutUs} alt="pabble-about-us" /><br/><br/>
        <p>Pabble aspires to be the web's most popular destination for news and story enthusiasts.</p>
        <p>The purpose of Pabble is to help spread and expand knowledge across the world. We encourage individuals with information to share it with people who need it the most, bring people with diverse viewpoints together to better understand one another, and empower everyone to contribute their knowledge for the benefit of the rest of the world.</p>
        <p>Pabble is home to hundreds of communities, limitless discussions, and genuine human connections. There's a Pabble community for everyone, whether you're interested in breaking news, sports, TV fan theories, or a never-ending stream of the internet's prettiest animals. If you’re familiar with Reddit, Slashdot, or Usenet, it’s pretty similar. Pabble does two main things differently though. The first is that we value freedom of speech and the idea of decentralization. We believe your opinions and views matter and it should not be silenced by small group of people. Therefore, post moderation conducted by site admins and mods are far and between at Pabble and is only limited to posts that are unequivocally misinformation or illegal content. We give the ability to moderate the site to our community members, not the site admins or mods. This brings us to our second point - the communities are democratic, they elect their own leadership and content. The mod feature is still in development and will be implemented soon. However, the intent is for individual community members to elect or impeach the community mods. In other community forums, the mods are given ultimate authority over regular users. In addition, community members will also be able to elect their content through voting system. Posts that reach a certain downvote % will receive less visibility or even deleted. This way, the community members will have democratic authority in what contents should remain in the community and keep their communities clean.</p>
        <p>As part of Pabble, you will have the opportunity to share information from many disciplines with the rest of the world. We are not limited to any one country; we want to share tales and highlights from all around the world. The objective is for everyone to have access to information. Users do not need to register an account to view posts and discussions in the community. Feel free to click on any community that interests you and jump right in!</p>

      </LeftSide>

      <SidebarWrapper>

        <Route component={SidebarContainer} />
        <Route component={PageSidebarContainer} />

      </SidebarWrapper>
    </Wrapper>
  )
};

export default About;
