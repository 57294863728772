import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../util/withAuth';
import { attemptChangeCountry } from '../../actions/auth';
import ChangeCountryForm from './Component';

const mapStateToProps = state => ({
    loading: state.auth.loading,
    user: state.auth.user
});

const mapDispatchToProps = { attemptChangeCountry };

let ChangeCountryContainer = ChangeCountryForm;

ChangeCountryContainer = compose(
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChangeCountryContainer);

ChangeCountryContainer = reduxForm({
    form: 'changecountry',
    enableReinitialize: true,
    immutableProps: ['initialValues']

})(ChangeCountryContainer)

ChangeCountryContainer = connect(
    state => ({
        initialValues: {
            country: state.auth.user.country,


        }
    })
)(ChangeCountryContainer)

export default compose(ChangeCountryContainer)
