import { contactUs as contactService } from '../util/api';
import { reset } from 'redux-form';
export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_ERROR = 'CONTACT_ERROR';


export const contactUs = (data) => async dispatch => {

    dispatch({
        type: CONTACT_REQUEST
    });

    try {
        const res = await contactService(data);

        dispatch({
            type: CONTACT_SUCCESS,
            payload: res.data,
            success: { message: "We have received your query, we will get back to you." }
        });
        dispatch(reset('contact'));


    } catch (error) {
        dispatch({
            type: CONTACT_ERROR,
            payload: error
        });
    }
};