import React from 'react';
import styled from 'styled-components';
import { transition, wideFont } from '../../helpers';

const Label = styled.label`
  ${transition('color', 'background-color')};
  ${wideFont};

  display: block;
  flex: 1 1 100%;
  // border: 1px solid ${props => props.theme.accent};
  width: 100%;
  padding: 8px;
  // background: ${props => (props.active ? props.theme.accent : 'transparent')};
  cursor: pointer;
  text-align: left;
  color: ${props => (props.active ? '#ffffff' : props.theme.accent)};
  outline: 0;

    
`;

const CheckBoxGroupOptions = props => (
  <>
    <input
      type='checkbox'
      name={props.input.name}
      id={props.input.name}
      onChange={props.onClick}
    />
    <Label htmlFor={props.input.name} active={props.active}>
      {props.label}
    </Label>
  </>
);

export default CheckBoxGroupOptions;
