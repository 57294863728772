
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
const showSuccess = success => ({ type: SHOW_SUCCESS, success });

export const HIDE_SUCCESS = 'HIDE_SUCCESS';
const hideSuccess = () => ({ type: HIDE_SUCCESS });
let timeout;
export const showSuccessWithTimeOut = success => dispatch => {
  dispatch(showSuccess(success));
  clearTimeout(timeout);
  timeout = setTimeout(() => dispatch(hideSuccess()), 5000);
};

export const hideSuccessClearTimeout = () => dispatch => {
  dispatch(hideSuccess());
  clearTimeout(timeout);
};
