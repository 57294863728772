import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import Form from '../shared/form/Form';
import renderField from '../shared/form/renderField';
import SubmitButton from '../shared/form/SubmitButton';



const postTypes = [
  {
    label: 'link',
    value: 'link'
  },
  {
    label: 'image',
    value: 'image'
  },
  {
    label: 'video',
    value: 'video'
  },
  {
    label: 'text',
    value: 'text'
  }
];

const CreatePostForm = (props) => {


  let categories = useSelector(state => state.categories.items)
  useEffect(() => {
    const { token, post, history } = props;
    if (!token) history.push('/');

    if (post) history.push(`/p/${post.category}/${post.id}`);
  }, [props.token, props.post])


  useEffect(() => {
    props.fetchCategories({})
  }, [])

  const onSubmit = post => {
    if (post.category === "") {
      post.category = categories[0].slug
    }
    return props.attemptCreatePost(post);
  }

  const mapCategories = () =>
    categories.map((category, index) => (
      <option key={index} value={category.slug}>
        {category.title}

      </option>
    ));

  return (
    <Form
      loading={props.isFetching}
      onSubmit={props.handleSubmit(onSubmit)}
      wide
    >
      <Field
        name='type'
        label='type'
        type='radiogroup'
        component={renderField}
        options={postTypes}
      />
      <Field
        name='category'
        label='community'
        type='select'
        component={renderField}
      >
        {mapCategories()}
      </Field>
      <Field name='title' label='Title' type='text' component={renderField} />
      {props.form.values.type === 'link' && (
        <Field name='url' label='Link URL' type='url' component={renderField} />
      )}
      {props.form.values.type === 'image' && (
        <Field name='url' label='Imgur URL' type='url' component={renderField} />
      )}
      {props.form.values.type === 'video' && (
        <Field name='url' label='Youtube URL' type='url' component={renderField} />
      )}
      {props.form.values.type === 'text' && (
        <Field name='text' label='Text' type='textarea' component={renderField} />
      )}
      <SubmitButton type='submit'>Create Post</SubmitButton>
    </Form>
  );
}


export default CreatePostForm;
