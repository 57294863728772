import React from 'react';
import LoadingIndicatorBox from '../shared/LoadingIndicator/Box';
import Empty from '../shared/Empty';
import PostDetailPost from './Post';
import PostDetailInfoBarContainer from './InfoBar/Container';
import CommentFormContainer from '../CommentForm/Container';
import PostDetailCommentSection from './CommentSection';
import { PostDetailContext } from './context';

class PostDetail extends React.Component {
  state = {
    showComments: false,
    showReplyForm: '',
    setShowReplyForm: (id) => {
      if (id === this.state.showReplyForm) {
        this.setState({ showReplyForm: "" })
      } else {
        this.setState({ showReplyForm: id })
      }
    }
  }

  componentDidMount() {
    this.props.fetchPost(this.props.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.post !== prevProps.post && this.props.post === null) {
      this.props.history.goBack();
    }
  }
  render() {
    const { post } = this.props;
    if (this.props.isFetching) return <LoadingIndicatorBox />;
    if (!post) return <Empty />;
    return (
      <>
        <PostDetailPost {...post} />

        <PostDetailInfoBarContainer
          id={post.id}
          views={post.views}
          upvotePercentage={post.upvotePercentage}
          author={post.author}
        />

        {this.props.token && <CommentFormContainer id={post.id} />}

        <PostDetailContext.Provider value={this.state}>{post.upvote}
          <PostDetailCommentSection comments={post.comments} />
        </PostDetailContext.Provider>

      </>
    );
  }
}

export default PostDetail;
