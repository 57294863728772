import React from 'react';
import styled from 'styled-components/macro';
import { Route } from 'react-router-dom';
import ProfileMainSection from './ProfileMainSection';
import ProfileSidebarContainer from './ProfileSidebar/Container';
import ChangePasswordContainer from '../ChangePassword/Container';
import UpdateEmailContainer from '../UpdateEmail/Container';
import ChangeCountryContainer from '../ChangeCountry/Container';
import MyCommunityListContainer from '../MyCommunities/Container';
import PostListContainer from '../PostList/Container';
import { attemptResendVerifyEmail } from '../../actions/auth'
import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../util/withAuth';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;

const NotificationAlert = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10vw;
  height: 30px;
  font-size: 15px;
  font-weight: 400;
  padding: .33em .5em;
  border: 1px solid ${({ dark }) => dark ? "#333" : "#ebedf0"};
  border-radius: 2px;
  color: ${({ dark }) => dark ? "#b0b8bf" : " #5c5e60"};
  background-color:  ${({ dark }) => dark ? "#262626" : " #fff"};
  margin-bottom: 20px;
  justify-content:center;
  text-align: center;
  a{
    color: ${({ dark }) => dark ? "#b0b8bf" : " #5c5e60"};
  }
  @media (max-width: 1024px) {
    margin: 0 5vw;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const Profile = ({ attemptResendVerifyEmail, user, dark }) => {

  const resendEmail = (e) => {
    e.preventDefault();
    attemptResendVerifyEmail(user.email)
  }

  return (
    <>
      {user && !user.status &&
        <NotificationAlert dark={dark}>
          This account is not verified yet, please click <a href="/" onClick={(e) => { resendEmail(e) }} className="verify-link">here</a> to verify.
        </NotificationAlert>
      }

      <Wrapper>
        <ProfileMainSection>
          <Route path='/profile/change-password' component={ChangePasswordContainer} />
          <Route path='/profile/update-email-address' component={UpdateEmailContainer} />
          <Route path='/profile/change-country' component={ChangeCountryContainer} />
          <Route path='/profile/my-communities' component={MyCommunityListContainer} />
          <Route
            exact
            path='/profile/my-posts'
            render={() => (
              <PostListContainer username={user && user.username} />
            )}
          />


          <Route exact path='/u/:username' render={({ match, location }) => {
            return <PostListContainer username={match.params.username} />
          }} />

        </ProfileMainSection>
        <Route component={ProfileSidebarContainer} />
      </Wrapper>
    </>
  );
}
const enhance = compose(
  withAuth,
  connect(null, { attemptResendVerifyEmail })
);

export default enhance(Profile);

