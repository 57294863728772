import React from 'react';
import styled from 'styled-components/macro';
import ReactPlayer from "react-player"

const Wrapper = styled.div`
  margin: 8px -8px;
  border: 1px solid ${props => props.theme.border};
  border-left: none;
  border-right: none;
  padding: 8px;
  background-color: ${props => props.theme.inputBackground};
`;

const PostContentFullText = props => (
  <Wrapper>
    <div className='player-wrapper'>
    <ReactPlayer className='react-player'
        url={props.children}
        controls='true'
        width='100%'
        height='100%'
    />
    </div>
  </Wrapper>
);

export default PostContentFullText;
