
import React from 'react';
import styled from 'styled-components/macro';
import { Route } from 'react-router-dom';

import CommunityListContainer from './Container';
import SidebarContainer from '../Sidebar/Container';
import PageSidebarContainer from '../PageSidebar/Container';
import PostHeaderContainer from '../PostHeader/Container';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  // border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  // background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainSection = styled.main`
  flex: 1;
  min-width: 0;
`;

const Community = ({ dark }) => {

  return (
    <Wrapper>
      <MainSection>
        <Route render={({ location }) => (
          <>
            <PostHeaderContainer title={"All Communities"} dark={dark} desc={false} showSubscribe={false} />
            <CommunityListContainer dark={dark} />

          </>
        )} />

      </MainSection>
      <SidebarWrapper>

        <Route component={SidebarContainer} />

        <Route component={PageSidebarContainer} />

      </SidebarWrapper>
    </Wrapper>

  )
}

export default Community;
