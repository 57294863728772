import React from 'react';
import styled from 'styled-components/macro';
import CommentDetailContainer from './Detail/Container';
import CommentContent from './Content';

import { attemptEditComment } from '../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';
import { PostDetailContext } from '../PostDetail/context'

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};
  display:flex;
  flex-direction:row;
  
    
  @media (max-width: 768px) {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const WrapperDetail = styled.div`
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};
    
  @media (max-width: 768px) {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const Comment = ({ body, id, votes, score, comments, full, deleted, author, postId, updated, edited, ...details }) => {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)

  const showEdit = () => {
    if (auth.token && auth.user && (auth.user.id === author._id || auth.user.admin)) {
      return true

    }
    return false
  }

  const editText = (text) => {


    dispatch(attemptEditComment({
      id,
      comment: text,
      postId
    }))

  }
  return (
    <>
      <Wrapper>
        <CommentContent showEdit={showEdit()} editText={editText} updated={updated} edited={edited} deleted={deleted}>{deleted ? "This comment has been deleted by user or site administrator" : body}</CommentContent>
      </Wrapper>
      <WrapperDetail>
        <PostDetailContext.Consumer>{
          (props) => {
            return <CommentDetailContainer {...details} {...props} votes={votes} score={score} deleted={deleted} author={author} postId={postId} />
          }}
        </PostDetailContext.Consumer>
      </WrapperDetail>

    </>
  );
};

export default Comment;
