import React, { useState } from 'react';
import styled from 'styled-components/macro';
import CommentListItem from './Item';
import { wideFont } from '../shared/helpers';

const List = styled.ul`
  margin-top: 16px;
  list-style: none;

`;
const Wrapper = styled.nav`

  ${wideFont};

  position: sticky;
  display: flex;
  margin-bottom: 10px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  height: 40px;
  background-color: ${props => props.theme.foreground};
  user-select: none;
  margin-left: 2rem;
    margin-top: 1rem;

   @media (max-width: 1024px) {
    height:auto;
  }

`;

const hideComments = e => {
  e.preventDefault()
  if (e.currentTarget.nextElementSibling.style.height === 'auto') {
    e.currentTarget.nextElementSibling.style.height = 0
  } else {
    e.currentTarget.nextElementSibling.style.height = 'auto'
  }
}

const replyTree = (comments, id = '0', link = 'parent') =>
  comments
    .filter(item => item[link] === id)
    .map(item => ({ ...item, children: replyTree(comments, item._id) }));

const mapComments = (comments, noNest = false) => {
  if (!noNest) {
    comments = replyTree(comments)
  }
  return comments.map((comment, index) => {



    return <>

      <CommentListItem key={index} {...comment} />
      {comment.children.length > 0 &&
        <>

          <a className="continue-thread" href="/" onClick={(e) => { hideComments(e) }}>Continue this thread →</a>

          <div className="list-comments-children">
            <List>{mapComments(comment.children, true)}</List>
          </div>
        </>
      }

    </>

  })

};



const CommentList = ({ comments, dark }) => {


  const [sortBy, setSortBy] = useState('new')

  const sortComments = (comments, sortBy) => {

    switch (sortBy) {
      case 'new':
        comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        break;
      case 'top':
        comments.sort((a, b) => b.score - a.score);
        break;
      case 'old':
        comments.sort((a, b) => new Date(a.created) - new Date(b.created));
        break;
      default:
        comments.sort((a, b) => b.score - a.score);
        break;
    }

    return comments;
  }

  return comments && <>
    <Wrapper >
      <nav className={`navbar navbar-expand-lg ${dark ? "navbar-dark" : "navbar-light"}`}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/" onClick={(e) => { e.preventDefault() }}>Sort Comments By</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#commentFilter" aria-controls="commentFilter" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse nav-item" id="commentFilter">


            <ul className="navbar-nav">
              <li>
                <a className={`nav-link ${sortBy === 'new' && "active fw-bold"}`} href="/" aria-current="page" onClick={(e) => { e.preventDefault(); setSortBy("new") }}>New</a>
              </li>

              <li className="nav-item">
                <a className={`nav-link ${sortBy === 'top' && "active fw-bold"}`} href="/" aria-current="page" onClick={(e) => { e.preventDefault(); setSortBy("top") }}>Top</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${sortBy === 'old' && "active fw-bold"}`} href="/" aria-current="page" onClick={(e) => { e.preventDefault(); setSortBy("old") }} >Old</a>
              </li>

            </ul>


          </div>
        </div>
      </nav>
    </Wrapper>
    <div className="post-comments">
      <List>{mapComments(sortComments(comments, sortBy))}</List>
    </div>

  </>
}

export default CommentList;
