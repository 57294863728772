import React from 'react';
import Empty from '../shared/Empty';
import CommentList from '../CommentList';

const PostDetailCommentSection = ({ comments }) => (
  <>

    {!comments || comments.length === 0 ? (
      <Empty comments />
    ) : (

      <div className="comment-list-wrapper">
        <CommentList comments={comments} />
      </div>
    )}
  </>
);

export default PostDetailCommentSection;
