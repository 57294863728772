import React from 'react'
import styled from 'styled-components/macro';

import Empty from '../shared/Empty';
import NavLink from '../shared/NavLink'

const Item = styled.li`
	display:flex;
	justify-content:'space-between';
    margin-bottom: 5px;
    background-color: ${props => props.theme.foreground};
    // border: 1px solid ${props => props.theme.border};
    border-radius: 2px; 
  :not(:first-child) {
    border-top: 1px solid ${props => props.theme.border};
  }
`;
const NavLinkItem = styled(NavLink)`

    display: block;
    padding: 10px 20px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
	flex-grow:1;
`
const List = styled.ul`
  list-style: none;
  // border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  padding-left:0px;

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const Subscribe = styled.button`

background: #1890ff;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:5px 0;
    margin-right:5px;
`

const UnSubscribe = styled.button`

background: #f5222d;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:5px 0;
    margin-right:5px;
`


class MyCommunities extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userComm: []
    }

  }
  componentDidMount() {
    this.props.fetchCategories({})

    // let userCommunity = this.props.categories.filter((i) => this.props.userCommunities.includes(i._id))

    // this.setState({ userComm: userCommunity })
  }

  static getDerivedStateFromProps(nextProps, nextState) {


    let userCommunity = nextProps.categories.filter((i) => nextProps.userCommunities.includes(i._id))


    return { userComm: userCommunity }
  }

  mapPosts = () =>
    this.state.userComm.map((category, index) => {
      return (
        <Item key={index}>
          <NavLinkItem to={`/p/${category.slug}`}>{category.title}</NavLinkItem>
          {this.props.userCommunities.includes(category._id)

            ?
            <UnSubscribe onClick={() => {
              this.props.subScribeUserCommunity({
                communityId: category._id,

              }, this.props.token, "unsubscribe")
            }} >Unsubscribe</UnSubscribe>
            :
            <Subscribe onClick={() => {
              this.props.subScribeUserCommunity({
                communityId: category._id,

              }, this.props.token, "subscribe")
            }} >Subscribe</Subscribe>
          }
        </Item>
      )
    });

  render() {
    if (!this.state.userComm || this.state.userComm.length === 0) return <Empty />;
    return <List>{this.mapPosts()}</List>;
  }
};
export default MyCommunities
