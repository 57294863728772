import React from 'react';
import { Field } from 'redux-form';
import Form from '../shared/form/Form';
import renderField from '../shared/form/renderField';
import SubmitButton from '../shared/form/SubmitButton';
import queryString from 'query-string'
import { passwordValidator } from '../../util/validators';


class ResetPasswordForm extends React.Component {
    componentDidMount() {
        this.redirectIfLoggedIn();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.redirectIfLoggedIn();
    }

    redirectIfLoggedIn() {
        // if (this.props.token) this.props.history.push('/');
    }

    onSubmit = ({ password }) => {
        let params = queryString.parse(this.props.location.search)

        this.props.attemptResetPassword(params.verification, password)
    };

    render() {
        return (
            <Form
                loading={this.props.loading}
                onSubmit={this.props.handleSubmit(this.onSubmit)}
            >
                <Field
                    name='password'
                    label='password'
                    type='password'
                    component={renderField}
                    validate={passwordValidator}
                />
                <Field
                    name='password2'
                    label='confirm password'
                    type='password'
                    component={renderField}
                />

                <SubmitButton type='submit'>Submit</SubmitButton>
            </Form>
        );
    }
}

export default ResetPasswordForm;
