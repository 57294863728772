import React from 'react';
import styled from 'styled-components/macro';
import SidebarList from './ItemList';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileSidebar = ({ token, user }) => (
  <Wrapper>
    {token &&
      <SidebarList username={(user && user.username) || ""} />}
  </Wrapper>
);

export default ProfileSidebar;
