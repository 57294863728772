import { connect } from 'react-redux';
import { fetchCategories, subScribeUserCommunity, fetchUserCommunities } from '../../actions/categories';
import Communities from './Component';

export const mapStateToProps = state => ({
	categories: (
		state.categories.items.sort(function (a, b) {
			if (a.title < b.title) { return -1; }
			if (a.title > b.title) { return 1; }
			return 0;
		})),
	userCommunities: state.categories.userCommunities,
	isFetching: state.categories.isFetching,
	loadMore: state.categories.loadMore,
	token: state.auth.token,
	user: state.auth.user
});

const mapDispatchToProps = { fetchCategories, subScribeUserCommunity, fetchUserCommunities };

const CommunityListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Communities);

export default CommunityListContainer;
