import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { overflow, link } from '../../shared/helpers';

const Wrapper = styled.div`
  display: flex;

  * {
    ${overflow};

    display: block;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    text-decoration: none;
    color: ${props => props.theme.normalText};
    ${props => props.full && 'white-space: unset'};
  }

  a {
    ${link({ underline: false })};
    // color: ${props => props.children.props.userPins ? "#1890ff" : props.theme.normalText};
    &:visited{
  color: ${props => props.full === undefined && (props.children.props.userPins ? "#5aadf9" : props.theme.visitedText)};
}
  }
`;


const Pinned = styled.span`
display: inline;
margin-right: 5px;
color: #1890ff;
font-weight: bold;
`
const renderTitle = props => {
  switch (props.type) {
    case 'link':
      //if (props.url) return <a target="_blank" rel="noopener noreferrer" href={props.url} >{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title} </a>;
      return <Link
        to={`/p/${props.category}/${props.id}`}>{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title}</Link >;

    case 'text':
      // if (props.full) return <Pinned>{props.title}</Pinned>;
      return <Link
        to={`/p/${props.category}/${props.id}`}>{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title}</Link>;

    case 'image':
      // if (props.full) return <Pinned>{props.title}</Pinned>;
      return <Link
        to={`/p/${props.category}/${props.id}`}>{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title}</Link>;

    case 'video':
      // if (props.full) return <Pinned>{props.title}</Pinned>;
      return <Link
        to={`/p/${props.category}/${props.id}`}>{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title}</Link>;

    default:
      return <Link
        to={`/p/${props.category}/${props.id}`}>{props.pinned && <Pinned>[Pinned]</Pinned>}{props.title}</Link>;
  }
};

const PostContentTitle = props => (
  <Wrapper full={props.full}>{renderTitle(props)}</Wrapper>
);

export default PostContentTitle;
