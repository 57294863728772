import React from 'react';
import styled from 'styled-components/macro';
import PostListItem from './Item';
import Empty from '../shared/Empty';

const List = styled.ul`
  list-style: none;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  padding-left:0px;

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

class PostList extends React.Component {
  constructor(props) {
    super(props)

    this.state = { page: 1 }

  }

  loadPosts = (page) => {
    const { username, category, sort } = this.props;

    if (username) return this.props.fetchProfile(username);
    return this.props.fetchPosts(category, sort, page);
  };

  handleScroll = () => {
    if (window.scrollY + window.innerHeight + 1 >=
      document.documentElement.scrollHeight) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.loadPosts(this.state.page);
      })
    }
  }


  componentDidMount() {
    this.loadPosts(1);
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.category !== prevProps.category ||
        this.props.username !== prevProps.username ||
        this.props.sort !== prevProps.sort)
    ) {
      this.loadPosts(1);
    }

    if (!this.props.loadMore) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  mapPosts = () =>
    this.props.posts.map((post, index) => (
      <PostListItem key={index} {...post} community={this.props.category} sort={this.props.sort} />
    ));

  render() {
    // if (this.props.isFetching) return <LoadingIndicatorBox />;
    if (!this.props.posts || this.props.posts.length === 0) return <Empty />;
    return <List>{this.mapPosts()}</List>;

  }
}

export default PostList;
