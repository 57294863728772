import React from 'react';
import styled from 'styled-components/macro';
import SideBarPageItem from './PageItem';
import pages from '../../../pages';

const PageList = styled.nav`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  flex-wrap: wrap;
  padding-top: 10px;

`;
const Copyright = styled.p`
  padding: 3px 15px;
  font-size: 11px;
  text-decoration: none;
  color: ${props => props.theme.normalText};
  margin-top:10px;
`;

const mapPages = pages =>
    pages.map((page, index) => (
        <SideBarPageItem key={index} page={page} />
    ));

const SidebarPageList = () => (
    <>
        <PageList>

            {mapPages(pages)}

        </PageList>

        <Copyright>Pabble Inc 2021. All Right Reserved</Copyright>
    </>
);

export default SidebarPageList;
