import React from 'react';
import styled from 'styled-components/macro';
import Markdown from '../shared/Markdown';
import moment from 'moment';
import EditButton from '../shared/EditButton';

const Content = styled.div`
  padding: 12px;
  font-style: ${({ deleted }) => deleted === true ? 'italic' : 'normal'};
  
  width:100%;
`;

const TextArea = styled.textarea`
   border: 1px solid ${props => props.theme.border};
  padding: 8px;
  background-color: ${props => props.theme.inputBackground};
  width:100%;
  height:50px;
 outline:none;
color: ${props => props.theme.normalText};
font-size:14px;
 
`;
const WrapperEdit = styled.div`
  display: inline-block;
  padding: 0px 0;
  align-self:flex-end;
  font-size: 13px;
  margin-bottom:0px;
 
text-transform:capitalize;
position: absolute;
right: 0;
bottom: 8px;
right: 65px;
color: ${props => props.theme.normalText};

`;

const StyledDiv = styled.div`
   font-weight: 400;
  color: ${props => props.theme.mutedText};
  margin-top:7px;
  font-size:12px;
`;

const CommentContent = props => {
  const [comment, setComment] = React.useState(props.children);
  const [edit, setEdit] = React.useState(false);

  return (
    <Content deleted={props.deleted} updated={props.updated} showEdit={props.showEdit}>
      {edit ?

        <TextArea onChange={(e) => { setComment(e.target.value) }}>{props.children}</TextArea>
        :
        <Markdown>{props.children}</Markdown>
      }
      {
        props.showEdit ?


          edit ?

            <WrapperEdit>
              <EditButton onClick={() => {
                setEdit(false)
                props.editText(comment)
              }}>update</EditButton>
            </WrapperEdit>
            :
            <WrapperEdit>
              <EditButton onClick={() => {
                setEdit(true)
              }}>Edit</EditButton>
            </WrapperEdit>


          :

          null


      }
      {props.edited &&
        <StyledDiv>
          Last edited {moment(props.updated).fromNow()}
        </StyledDiv>
      }
    </Content>
  )
};

export default CommentContent;
