import {
	getCategories,
	getUserCommunities,
	subScribeCommunity,
	unSubScribeCommunity,
} from '../util/api';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_FEATURED_CATEGORIES_REQUEST = 'FETCH_FEATURED_CATEGORIES_REQUEST';
export const FETCH_USER_COMMUNITIES = 'FETCH_USER_COMMUNITIES';

export const fetchCategories = (data) => async dispatch => {

	try {
		const categories = await getCategories(data);

		if (data.type !== undefined) {
			dispatch({
				type: FETCH_FEATURED_CATEGORIES_REQUEST,
				payload: categories
			});
			return
		}

		dispatch({
			type: FETCH_CATEGORIES_REQUEST,
			payload: categories
		});

	} catch (error) {

	}
};

export const fetchUserCommunities = (token) => async dispatch => {

	try {
		const communities = await getUserCommunities(token);

		dispatch({
			type: FETCH_USER_COMMUNITIES,
			payload: communities
		});

	} catch (error) {

	}
};
export const subScribeUserCommunity = (data, token, type = "subscribe") => async dispatch => {

	try {
		const communities = await subScribeCommunity(data, token);
		let dispachData = {
			type: FETCH_USER_COMMUNITIES,
			payload: communities
		}

		if (type === "subscribe") {
			dispachData.success = { message: type === "subscribe" ? "You have successfully subscribed to this community" : "" }
		}

		if (type === "unsubscribe") {
			dispachData.error = { message: type === "unsubscribe" ? "You have successfully unsubscribed to this community" : "" }
		}


		dispatch(dispachData);


	} catch (error) {

	}
};
export const unSubScribeUserCommunity = (data, token) => async dispatch => {

	try {
		await unSubScribeCommunity(data, token);


	} catch (error) {

	}
};


