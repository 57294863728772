import React, { useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { link } from '../../shared/helpers';
import Author from '../../shared/Author';

import { InlineShareButtons } from 'sharethis-reactjs';

import DeleteButton from '../../shared/DeleteButton';

import { attemptDeletePost, onCreatePin, onDeletePin } from '../../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
  font-size: 13px;
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > * {
    margin-right: 4px;
  }

  & > a {
    ${link};
  }

  & > span {
    color: ${props => props.theme.mutedText};
  }
  & > span img{
  width:15px;
  height:15px;
  font-weight:bold;
  }
`;

const SocialWrapper = styled.div`
      font-size: 13px;
    display: table-cell;
    padding: 8px;
    background-color: '#fafafa';
    border-radius: 10px;
    margin-left: 5px;

}


`;


const StyledLink = styled(Link)`
  ${link};

  font-weight: 500;
  color: ${props => props.theme.normalText};
  margin-top:7px;
`;
const StyledLinkPin = styled(Link)`
  ${link};

  font-weight: 500;
  color: ${props => props.theme.normalText};
  margin-top:7px;
  i{
    margin-top:5px;s
  }
  span{
    padding-left:2px;
    padding-bottom:3px;
  }
`;
const WrapperDelete = styled.div`
  display: inline-block;
  padding: 0px 0;
  justify-content:space-between;
  font-size: 13px;
  margin-bottom:0px;
  flex-grow:1;
text-transform:capitalize;
`;


const PostContentDetail = props => {
  const [socialShare, setSocialShare] = useState()
  const auth = useSelector(state => state.auth)

  const dispatch = useDispatch()
  return (
    <Wrapper>
      <Link to={`/p/${props.category}/${props.id}`}>
        {props.commentCount} comment{props.commentCount !== 1 ? 's' : null}
      </Link>
      <Link to={`/p/${props.category}`}>{`/p/${props.category}`}</Link>
      <span>by</span>
      <Author username={props.author && props.author.username} admin={props.author && props.author.admin} />
      <span>{moment(props.created).fromNow()}</span>

      {auth.token && auth.user && auth.user.admin &&

        (props.pinned ?


          <StyledLinkPin onClick={(e) => { e.preventDefault(); dispatch(onDeletePin({ id: props.id, pinned: props.community ? "community" : "home", sort: props.sort, category: props.community }, auth.token)) }}>
            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
            <span>Unpin</span>
          </StyledLinkPin>

          :

          <StyledLinkPin onClick={(e) => { e.preventDefault(); dispatch(onCreatePin({ id: props.id, pinned: props.community ? "community" : "home", sort: props.sort, category: props.community }, auth.token)) }}>
            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
            <span>Pin</span>
          </StyledLinkPin>
        )
      }

      <StyledLink onClick={() => { setSocialShare(!socialShare) }} style={{
        cursor: 'pointer'
      }}> Share </StyledLink>

      <WrapperDelete>
        {auth.token && auth.user &&
          (auth.user.id === props.author._id ||
            auth.user.admin) && (
            <DeleteButton onClick={() => {


              if (window.confirm("Are you sure you want to delete?") === true) {
                dispatch(attemptDeletePost(props.id))
              }


            }} />
          )}
      </WrapperDelete>


      {socialShare &&

        <SocialWrapper>
          <InlineShareButtons
            config={{
              alignment: 'center',  // alignment of buttons (left, center, right)
              color: 'social',      // set the color of buttons (social, white)
              enabled: true,        // show/hide buttons (true, false)
              font_size: 25,        // font size for the buttons
              labels: 'null',        // button labels (cta, counts, null)
              language: 'en',       // which language to use (see LANGUAGES)
              networks: [           // which networks to include (see SHARING NETWORKS)
                'facebook',
                'linkedin',
                'twitter',
                'reddit',
                'pinterest',
                'tumblr',
                'email',
                'whatsapp'
              ],
              padding: 10,          // padding within buttons (INTEGER)
              radius: 100,            // the corner radius on each button (INTEGER)
              show_total: false,
              size: 25,             // the size of each button (INTEGER)

              url: window.location.protocol + '//' + window.location.host + `/p/${props.category}/${props.id}`,
              description: props.text,       // (defaults to og:description or twitter:description)
              title: "Pabble - " + props.title,            // (defaults to og:title or twitter:title)
              message: props.text ? props.text + "\n" + window.location.protocol + '//' + window.location.host + `/p/${props.category}/${props.id}` : window.location.protocol + '//' + window.location.host + `/p/${props.category}/${props.id}`,     // (only for email sharing)
              subject: "Pabble - " + props.title,  // (only for email sharing)
              username: 'custom twitter handle' // (only for twitter sharing)
            }}
          />

        </SocialWrapper>
      }

    </Wrapper>

  );
}
export default PostContentDetail;
