import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../util/withAuth';
import { logout } from '../../actions/auth';
import PostHeader from './Component';
import { subScribeUserCommunity } from '../../actions/categories'

const mapDispatchToProps = { logout, subScribeUserCommunity };

const mapStateToProps = state => ({

    token: state.auth.token,
    user: state.auth.user,
    userCommunities: state.categories.userCommunities
});
const enhance = compose(
    withAuth,
    connect(mapStateToProps, mapDispatchToProps)
);

const PostHeaderContainer = enhance(PostHeader);

export default PostHeaderContainer;
