import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import theme from '../../theme';
import history from '../../util/history';
import GlobalStyle from '../../globalStyle';
import HeaderContainer from '../Header/Container';
import ErrorNotificationContainer from '../ErrorNotification/Container';
import SuccessNotificationContainer from '../SuccessNotification/Container';
import LoginFormContainer from '../LoginForm/Container';
import SignupFormContainer from '../SignupForm/Container';
import ForgotPasswordContainer from '../ForgotPasswordForm/Container';
import ResetPasswordContainer from '../ResetPasswordForm/Container';
import CreatePostFormContainer from '../CreatePostForm/Container';
import Profile from '../Profile';
import Home from '../Home';
import Pages from '../pages'
import Community from '../Communities'
import { fetchCategories, fetchUserCommunities } from '../../actions/categories'
import { logout } from '../../actions/auth'
import { useIdleTimer } from 'react-idle-timer'
import favicon from '../../assets/favicon.ico';


const App = props => {

  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token)

  const handleOnIdle = () => {
    let remember = localStorage.getItem('remember')
    if (!remember) {
      dispatch(logout());
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500
  })

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    link.href = favicon
  }, [])

  useEffect(() => {
    getCategories();

  }, [token])

  const getCategories = () => {
    try {

      dispatch(fetchCategories({ type: "featured" }))

      dispatch(fetchUserCommunities(token))
      // dispatch(fetchUserPins(token))

    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ThemeProvider theme={theme(props.dark)}>
      <Router history={history}>
        <>
          <GlobalStyle />
          <Route render={() => {
            return <HeaderContainer dark={props.dark} />
          }} />
          <Route component={ErrorNotificationContainer} />
          <Route component={SuccessNotificationContainer} />

          <Switch>
            <Route path='/login' component={LoginFormContainer} />
            <Route path='/forgot-password' component={ForgotPasswordContainer} />
            <Route path='/reset-password' component={ResetPasswordContainer} />
            <Route path='/signup' component={SignupFormContainer} />
            <Route path='/createpost' component={CreatePostFormContainer} />
            <Route path='/terms' component={Pages.Terms} />
            <Route path='/contact' component={Pages.Contact} />
            <Route path='/press' component={Pages.Press} />
            <Route path='/privacy' component={Pages.Privacy} />
            <Route path='/about' component={Pages.About} />
            <Route path='/guideline' component={Pages.Guideline} />
            <Route path='/communities' render={() => {
              return <Community {...props} />
            }} />

            <Route path='/u/:username' render={({ match, location }) => {
              return <Profile {...props} username={match.params.username} />
            }} />
            <Route path='/profile' render={() => {
              return <Profile {...props} />
            }} />
            <Route path='/' render={() => {
              return <Home {...props} />
            }} />
          </Switch>
        </>
      </Router>
    </ThemeProvider>
  )
};

export default App;
