import React from 'react';
import styled from 'styled-components/macro';
import Author from '../../shared/Author';
import CommentDetailTimestamp from './Timestamp';
import DeleteButton from '../../shared/DeleteButton';
import ReplyButton from '../../shared/ReplyButton';
import CommentReplyFormContainer from '../ReplyForm/Container'
import CommentVoteContainer from '../Vote/Container';

const Wrapper = styled.div`
  flex-directon:row;
  display:flex;
    padding: 0px 8px;
  font-size: 13px;
  margin-bottom:0px;

`;

const WrapperDelete = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-directon:row;
  justify-content:space-between;
  font-size: 13px;
  margin-bottom:0px;
  flex-grow:1;

`;

class CommentDetail extends React.Component {
  state = { hideReplyForm: true }

  deleteComment = () => this.props.attemptDeleteComment(this.props._id);

  hideShowReplyForm() {
    this.setState({
      hideReplyForm: !this.state.hideReplyForm
    })
  }

  render() {
    return (
      <>
        <Wrapper>

          <Author username={this.props.author && this.props.author.username} />
          <CommentDetailTimestamp created={this.props.created} />
          <CommentVoteContainer id={this.props._id} score={this.props.score} votes={this.props.votes} />

          <ReplyButton onClick={() => this.props.setShowReplyForm(this.props._id)} />
          {
            this.props.token && this.props.user &&
            this.props.deleted !== true &&
            (this.props.user.id === this.props.author._id ||
              this.props.user.admin) && (
              <WrapperDelete>
                <DeleteButton onClick={() => {

                  if (window.confirm("Are you sure you want to delete?") === true) {
                    this.deleteComment()
                  }
                }

                }
                />
              </WrapperDelete>

            )
          }

        </Wrapper>

        {
          this.props.showReplyForm && this.props.showReplyForm === this.props._id &&
          <CommentReplyFormContainer id={this.props._id} />
        }


      </>
    );
  }
}

export default CommentDetail;
