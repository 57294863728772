import React from 'react';
import queryString from 'query-string'
import styled from 'styled-components/macro';
import { Route } from 'react-router-dom';
import HomeMainSection from './MainSection';
import CategoryMenuContainer from '../CategoryMenu/Container';
import PostListContainer from '../PostList/Container';
import PostDetailContainer from '../PostDetail/Container';
import SidebarContainer from '../Sidebar/Container';
import PageSidebarContainer from '../PageSidebar/Container';
import PostFilterContainer from '../PostFilter/Container';
import PostHeaderContainer from '../PostHeader/Container';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;
const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  // border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  // background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    display: none;
  }
`;


const Home = ({ dark }) => {

  function getSortBy(q) {
    let params = queryString.parse(q)
    return params.sort || ""
  }
  return (
    <Wrapper>
      <HomeMainSection>

        <Route component={CategoryMenuContainer} />

        <Route exact path='/' render={({ location }) => (
          <>
            <PostHeaderContainer dark={dark} title={"Pabble"} desc="Dive into anything and everything" tag="Home" showSubscribe={false} />
            <PostFilterContainer dark={dark} />
            <PostListContainer sort={getSortBy(location.search)} category="" />

          </>
        )} />

        <Route
          exact
          path='/p/:category'
          render={({ match, location }) => (
            <>
              <PostHeaderContainer dark={dark} category={match.params.category} showSubscribe={true} />
              <PostFilterContainer dark={dark} />
              <PostListContainer category={match.params.category} sort={getSortBy(location.search)} />
            </>
          )}
        />

        <Route
          exact
          path='/p/:category/:post'
          render={({ match, history }) => (
            <PostDetailContainer id={match.params.post} history={history} />
          )}
        />

      </HomeMainSection>
      <SidebarWrapper>

        <Route component={SidebarContainer} />

        <Route component={PageSidebarContainer} />

      </SidebarWrapper>
    </Wrapper>
  )
};

export default Home;
