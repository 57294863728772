import React from 'react';
import styled from 'styled-components/macro';
import { NavLink, useLocation } from 'react-router-dom';
import { wideFont } from '../shared/helpers';

const Wrapper = styled.nav`

  ${wideFont};

  position: relative;
  z-index: 2;
  display: flex;
  margin-bottom: 10px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  height: 40px;
  background-color: ${props => props.theme.foreground};
  user-select: none;
  
   @media (max-width: 1024px) {
    margin: 0;
    height:auto;
  }

`;

const ListItem = styled.li`

  a{
    &.Active{
      color: ${props => props.theme.accent} !important;
    }
   
  } 
    ul{
      background-color: ${props => props.theme.foreground} !important;
      a{
        background-color: ${props => props.theme.foreground} !important;
        color: ${props => props.theme.normalText} !important;
        font-size:12px !important;
      }
    } 
`;

const PostFilter = ({ dark }) => {
  const params = useLocation()
  return (
    <Wrapper >
      <>
        <nav className={`navbar navbar-expand-lg ${dark ? "navbar-dark" : "navbar-light"}`}>
          <div className="container-fluid">
            <a className="navbar-brand" href="/" >Sort Post By</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse nav-item" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <ListItem>
                  <NavLink className={`nav-link fw-bold   ${(params.search.includes("sort=hot") || params.search === "") && 'Active'}`} onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} aria-current="page" to="?sort=hot" value="hot">Hot</NavLink>
                </ListItem>
                <ListItem className="nav-item dropdown">
                  <NavLink className={`nav-link  dropdown-toggle fw-bold ${(params.search.includes("sort=today") || params.search.includes("sort=week") || params.search.includes("sort=month") || params.search.includes("sort=year") || params.search.includes("sort=alltime")) && 'Active'}`} to="?sort=alltime" id="menuLinks" role="button" data-bs-toggle="dropdown" aria-expanded="false">Top</NavLink>

                  <ul className="dropdown-menu" id="subMenuDropdown" aria-labelledby="menuLinks" style={{ left: "30px", minWidth: "7rem" } }>
                    <NavLink className="dropdown-item fw-bold" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} to="?sort=today" value="today">Today</NavLink>
                    <NavLink className="dropdown-item fw-bold" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} to="?sort=week" value="week">This Week</NavLink>
                    <NavLink className="dropdown-item fw-bold" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} to="?sort=month" value="month">This Month</NavLink>
                    <NavLink className="dropdown-item fw-bold" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} to="?sort=year" value="year">This Year</NavLink>
                    <NavLink className="dropdown-item fw-bold" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} to="?sort=alltime" value="alltime">All Time</NavLink>
                  </ul>
                </ListItem>
                <ListItem className="nav-item">
                  <NavLink className={`nav-link  fw-bold ${params.search.includes("sort=new") && 'Active'}`} aria-current="page" to="?sort=new" onClick={() => { document.getElementById("subMenuDropdown").classList.remove("show") }} value="new">New</NavLink>
                </ListItem>
              </ul>
            </div>
          </div>
        </nav>
      </>
    </Wrapper >
  )
};

export default PostFilter;
