import React from 'react';
import styled from 'styled-components/macro';
import SidebarPageList from './PageList';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};
  margin-top:20px;
  max-height: 130px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Sidebar = ({ token }) => (
  <Wrapper>

    <SidebarPageList />

  </Wrapper>
);

export default Sidebar;
