import React from 'react';
import styled from 'styled-components/macro';
import PostContentTitle from './Title';
import PostContentPreview from './Preview';
import PostContentFullText from './FullText';
import PostContentFullLink from './FullLink';
import PostContentFullImage from './FullImage';
import PostContentFullVideo from './FullVideo';
import PostContentDetail from './Detail';

import { attemptEditPost } from '../../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 10px;
  min-width: 0; 
  position:relative;
`;

const renderContent = props => {
  switch (props.type) {
    case 'link':
      if (props.showFullPost) {
        return <PostContentFullLink>{props.url}</PostContentFullLink>;
      }
      return <PostContentPreview>{props.url}</PostContentPreview>;

    case 'image':
      if (props.showFullPost) {
        return <PostContentFullImage>{props.url}</PostContentFullImage>;
      }
      return <PostContentPreview>{props.url}</PostContentPreview>;

    case 'video':
      if (props.showFullPost) {
        return <PostContentFullVideo>{props.url}</PostContentFullVideo>;
      }
      return <PostContentPreview>{props.url}</PostContentPreview>;

    case 'text':
      if (props.showFullPost) {
        return <PostContentFullText edited={props.edited} updated={props.updated} showEdit={props.showEdit()} editText={props.editText}>{props.text}</PostContentFullText>;
      }
      return <PostContentPreview>{props.text}</PostContentPreview>;

    default:
      break;
  }
};

const PostContent = ({
  _id,
  url,
  title,
  type,
  text,
  commentCount,
  showFullPost,
  author,
  edited,
  updated,
  ...details
}) => {

  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const showEdit = () => {
    if (auth.token && auth.user && (auth.user.id === author._id || auth.user.admin)) {
      return true

    }
    return false
  }

  const editText = (text) => {


    dispatch(attemptEditPost({
      id: _id,
      text: text
    }))

  }

  return (
    <Wrapper>
      <PostContentTitle
        url={url}
        title={title}
        type={type}
        full={showFullPost}
        {...details}
      />
      {renderContent({ type, url, text, showFullPost, showEdit, editText, edited, updated })}
      <PostContentDetail commentCount={commentCount}
        title={title} text={text} url={url} type={type} author={author}  {...details} />
    </Wrapper>
  )
};

export default PostContent;
