import React from 'react';
import styled from 'styled-components/macro';
import SidebarListItem from './Item';
import SidebarListHeader from './Header';
import profileitems from '../../../../profileitems';

const ItemList = styled.nav`
  display: flex;
  flex-direction: column;
`;

const mapProfileItem = (profileitems, username) =>
  profileitems.map((profileitem, index) => (
    <SidebarListItem key={index} profileitem={profileitem} username={username} />
  ));

const SidebarList = ({username}) => (
  <ItemList>
    <SidebarListHeader />
    {mapProfileItem([...profileitems], username)}
  </ItemList>
);

export default SidebarList;
