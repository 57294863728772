import {
	FETCH_CATEGORIES_REQUEST,
	FETCH_FEATURED_CATEGORIES_REQUEST,
	FETCH_USER_COMMUNITIES

} from '../actions/categories';

const initialState = { items: [], featuredItems: [], userCommunities: [] };

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CATEGORIES_REQUEST:
			return { ...state, items: action.payload };
		case FETCH_FEATURED_CATEGORIES_REQUEST:
			return { ...state, featuredItems: action.payload };
		case FETCH_USER_COMMUNITIES:
			return { ...state, userCommunities: action.payload };
		default:
			return state;
	}
};



