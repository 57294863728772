import {
    FETCH_POSTS_SUCCESS,
    FETCH_POST_SUCCESS,
    CREATE_POST_SUCCESS,
    DELETE_POST_SUCCESS,
    CREATE_COMMENT_SUCCESS,
    DELETE_COMMENT_SUCCESS,
    VOTE_SUCCESS,

} from '../actions/posts';

import { FETCH_USER_COMMUNITIES } from '../actions/categories'
import {
    LOGIN_SUCCESS,
    SIGNUP_SUCCESS,
    CHANGE_COUNTRY_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    VERIFY_EMAIL_SUCCESS
} from '../actions/auth';
import {
    // hideSuccessClearTimeout, 
    showSuccessWithTimeOut
} from '../actions/success';

export default store => next => action => {
    next(action);
    switch (action.type) {
        case FETCH_POSTS_SUCCESS:
        case FETCH_POST_SUCCESS:
        case CREATE_POST_SUCCESS:
        case DELETE_POST_SUCCESS:
        case CREATE_COMMENT_SUCCESS:
        case DELETE_COMMENT_SUCCESS:
        case VOTE_SUCCESS:
        case LOGIN_SUCCESS:
        case CHANGE_COUNTRY_SUCCESS:
        case UPDATE_EMAIL_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
        case CHANGE_PASSWORD_SUCCESS:
        case SIGNUP_SUCCESS:
        case VERIFY_EMAIL_SUCCESS:
        case FETCH_USER_COMMUNITIES:
            if (store.getState().success !== "")
                // store.dispatch(hideSuccessClearTimeout());
                store.dispatch(showSuccessWithTimeOut(action.success));
            break;

        default:
            break;
    }
};
