import React from 'react';
import { Field } from 'redux-form';
import Form from '../../shared/form/Form';
import renderField from '../../shared/form/renderField';

import SubmitButton from '../../shared/form/SubmitButton';
import styled from 'styled-components/macro';


import SidebarContainer from '../../Sidebar/Container';
import PageSidebarContainer from '../../PageSidebar/Container';
import { emailValidator, required } from '../../../util/validators';

import { Route } from 'react-router-dom';


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;


  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;
const LeftSide = styled.main`
  flex: 1;
  min-width: 0;
    height: auto;
   color: ${props => props.theme.normalText};
    padding:20px;

    border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  // border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  // background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    display: none;
  }
`;



class ContactComponentForm extends React.Component {


  onSubmit = ({ name, email, phone, message }) => {
    this.props.contactUs({ name, email, phone, message });
  };

  render() {
    return (
      <Wrapper>
        <LeftSide>

          <h2>Contact Us</h2>
          <br />
          <p>We would love to hear from you! If you have any questions, concerns, or suggestions, please do not hesitate and use the contact form below to reach out to us.</p>
          <br />
          <Form
            loading={this.props.loading}
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            wide={"100%"}
          >
            <Field
              name='name'
              label='name'
              type='text'
              component={renderField}
              validate={required}

            />
            <Field
              name='email'
              label='email'
              type='text'
              component={renderField}
              validate={emailValidator}

            />
            {/* <Field
                            name='phone'
                            label='phone'
                            type='text'
                            component={renderField}
                            validate={required}

                        /> */}
            <Field
              name='message'
              label='message'
              type='textarea'
              component={renderField}
              validate={required}

            />

            <SubmitButton type='submit'>submit</SubmitButton>
          </Form>
        </LeftSide>

        <SidebarWrapper>

          <Route component={SidebarContainer} />

          <Route component={PageSidebarContainer} />

        </SidebarWrapper>
      </Wrapper>
    );
  }
}

export default ContactComponentForm;
