import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../util/withAuth';
import { attemptChangePassword } from '../../actions/auth';

import ChangePasswordForm from './Component';

const mapStateToProps = state => ({
    loading: state.auth.loading
});

const mapDispatchToProps = { attemptChangePassword };

const enhance = compose(
    reduxForm({ form: 'changepassword' }),
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

const ChangePasswordContainer = enhance(ChangePasswordForm);

export default ChangePasswordContainer;
