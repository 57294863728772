import {
    LOGIN_SUCCESS,
    SIGNUP_SUCCESS,
    CHANGE_COUNTRY_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    RESET_PASSWORD_SUCCESS,
    VERIFY_EMAIL_SUCCESS
} from '../actions/auth';

import { FETCH_USER_COMMUNITIES } from '../actions/categories'
import { CONTACT_SUCCESS } from '../actions/contact'
import { HIDE_SUCCESS } from '../actions/success';

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {


        case CHANGE_COUNTRY_SUCCESS:
        case CHANGE_PASSWORD_SUCCESS:
        case UPDATE_EMAIL_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS:
        case VERIFY_EMAIL_SUCCESS:
        case FETCH_USER_COMMUNITIES:
        case CONTACT_SUCCESS:
            return action.success ? action.success : null;

        case HIDE_SUCCESS:
            return null;

        default:
            return state;
    }
};
