import React from 'react';
import { Field } from 'redux-form';
import Form from '../shared/form/Form';
import renderField from '../shared/form/renderField';
import { emailValidator } from '../../util/validators';
import SubmitButton from '../shared/form/SubmitButton';

class UpdateEmailForm extends React.Component {

    state = {
        emailSent: false
    }

    componentDidMount() {
        this.redirectIfLoggedIn();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.redirectIfLoggedIn();
    }

    redirectIfLoggedIn() {
        // if (this.props.token) this.props.history.push('/');
    }

    onSubmit = ({ email }) => {
        this.props.attemptUpdateEmail({
            email,
            username: this.props.user.username
        })
    };

    sendVerifyEmail = (e) => {
        e.preventDefault();
        this.setState({ emailSent: true })
    }


    render() {

        return (
            <Form
                loading={this.props.loading}
                onSubmit={this.props.handleSubmit(this.onSubmit)}
            >
                <Field
                    name='email'
                    label='email'
                    type='text'

                    component={renderField}
                    validate={emailValidator}
                />

                <SubmitButton type='submit'>Submit</SubmitButton>
            </Form>
        );
    }
}

export default UpdateEmailForm;
