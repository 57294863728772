import { login, signup, forgot, resetPassword, emailVerify, changePassword, updateEmail, changeCountry, resendVerifyEmail } from '../util/api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

const loginRequest = { type: LOGIN_REQUEST };
const loginSuccess = data => ({ type: LOGIN_SUCCESS, token: data.token, success: data.success });
const loginError = error => ({ type: LOGIN_ERROR, error });

export const attemptLogin = (username, password, remember) => async dispatch => {

  dispatch(loginRequest);
  try {
    const token = await login(username, password);
    dispatch(loginSuccess({ token, success: { message: 'Login Successfully' } }));

    if (remember) {
      localStorage.setItem('remember', token)
    }

  } catch (error) {
    dispatch(loginError(error));
  }
};

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

const signupRequest = { type: SIGNUP_REQUEST };
const signupSuccess = data => ({ type: SIGNUP_SUCCESS, token: data.token, success: data.success });
const signupError = error => ({ type: SIGNUP_ERROR, error });

export const attemptSignup = (email, username, password, country) => async dispatch => {
  dispatch(signupRequest);
  try {
    const token = await signup(email, username, password, country);
    dispatch(signupSuccess({ token, success: { message: 'Registred Successfully' } }));
  } catch (error) {
    dispatch(signupError(error));
  }
};

export const VERIFY_EMAIL_REQUEST = ' VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = ' VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = ' VERIFY_EMAIL_ERROR';

const verifyEmailRequest = { type: VERIFY_EMAIL_REQUEST };
const verifyEmailSuccess = data => ({ type: VERIFY_EMAIL_SUCCESS, success: data.success });
const verifyEmailError = error => ({ type: VERIFY_EMAIL_ERROR, error });

export const attemptResendVerifyEmail = (email) => async (dispatch) => {
  dispatch(verifyEmailRequest);
  try {

    let checkTime = localStorage.getItem('verifyemail')
    if (checkTime) {
      checkTime = JSON.parse(checkTime)
      let diff = Math.floor((new Date().getTime() - new Date(checkTime.time).getTime()) / 1000)
      if (diff < 60) {
        dispatch(verifyEmailError({ message: `Please wait ${60 - diff} seconds for resending email verification` }));
        return
      }
    }

    await resendVerifyEmail(email);

    localStorage.setItem('verifyemail', JSON.stringify({
      time: new Date()
    }))

    dispatch(verifyEmailSuccess({ success: { message: 'Email Sent Successfully' } }));
  } catch (error) {
    dispatch(verifyEmailError(error));
  }
};

export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

const forgotRequest = { type: FORGOT_REQUEST };
const forgotSuccess = token => ({ type: FORGOT_SUCCESS, token });
const forgotError = error => ({ type: FORGOT_ERROR, error });

export const attemptForgot = (email) => async dispatch => {
  dispatch(forgotRequest);
  try {
    const token = await forgot(email);
    dispatch(forgotSuccess(token));
  } catch (error) {
    dispatch(forgotError(error));
  }
};

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

const resetPasswordRequest = { type: RESET_PASSWORD_REQUEST };
const resetPasswordSuccess = token => ({ type: RESET_PASSWORD_SUCCESS, token });
const resetPasswordError = error => ({ type: RESET_PASSWORD_ERROR, error });

export const attemptResetPassword = (email, password) => async dispatch => {

  dispatch(resetPasswordRequest);
  try {
    const token = await resetPassword(email, password);
    dispatch(resetPasswordSuccess({ token, success: { message: 'Password Updated Successfully' } }));
  } catch (error) {
    dispatch(resetPasswordError(error));
  }
};

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

const changePasswordRequest = { type: CHANGE_PASSWORD_REQUEST };
const changePasswordSuccess = data => ({ type: CHANGE_PASSWORD_SUCCESS, token: data.token, success: data.success });
const changePasswordError = error => ({ type: CHANGE_PASSWORD_ERROR, error });

export const attemptChangePassword = (data) => async dispatch => {

  dispatch(changePasswordRequest);
  try {
    const token = await changePassword(data);
    dispatch(changePasswordSuccess({ token, success: { message: 'Password Updated Successfully' } }));
  } catch (error) {
    dispatch(changePasswordError(error));
  }
};


export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';

const updateEmailRequest = { type: UPDATE_EMAIL_REQUEST };
const updateEmailSuccess = data => ({ type: UPDATE_EMAIL_SUCCESS, token: data.token, email: data.email, success: data.success });
const updateEmailError = error => ({ type: UPDATE_EMAIL_ERROR, error });

export const attemptUpdateEmail = (data) => async dispatch => {
  dispatch(updateEmailRequest);
  try {

    const token = await updateEmail(data);
    dispatch(updateEmailSuccess({ token, email: data.email, success: { message: 'E-Mail Updated Successfully' } }))
  } catch (error) {
    dispatch(updateEmailError(error));

  }
};

export const CHANGE_COUNTRY_REQUEST = 'CHANGE_COUNTRY_REQUEST';
export const CHANGE_COUNTRY_SUCCESS = 'CHANGE_COUNTRY_SUCCESS';
export const CHANGE_COUNTRY_ERROR = 'CHANGE_COUNTRY_ERROR';

const changeCountryRequest = { type: CHANGE_COUNTRY_REQUEST };
const changeCountrySuccess = data => ({ type: CHANGE_COUNTRY_SUCCESS, token: data.token, country: data.country, success: data.success });
const changeCountryError = error => ({ type: CHANGE_COUNTRY_ERROR, error });

export const attemptChangeCountry = (data) => async dispatch => {
  dispatch(changeCountryRequest);
  try {

    const token = await changeCountry(data);
    dispatch(changeCountrySuccess({ token, country: data.country, success: { message: 'Country Changed Successfully' } }));

  } catch (error) {
    dispatch(changeCountryError(error));
  }
};

export const EMAIL_VERIFY_REQUEST = 'EMAIL_VERIFY_REQUEST';
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS';
export const EMAIL_VERIFY_ERROR = 'EMAIL_VERIFY_ERROR';

const emailVerifyRequest = { type: EMAIL_VERIFY_REQUEST };
const emailVerifySuccess = token => ({ type: EMAIL_VERIFY_SUCCESS, token });
const emailVerifyError = error => ({ type: EMAIL_VERIFY_ERROR, error });

export const attemptEmailVerify = (email) => async (dispatch, getState) => {

  dispatch(emailVerifyRequest);
  try {

    const token = await emailVerify(email);
    dispatch(emailVerifySuccess(token));

  } catch (error) {
    dispatch(emailVerifyError(error));
  }
};


export const LOGOUT = 'LOGOUT';
export const logout = () => async dispatch => {
  dispatch({ type: LOGOUT })

  window.location.href = "/"
};
