import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { attemptCreateComment } from '../../../actions/posts';
import CommentReplyForm from './Component';

const mapDispatchToProps = { attemptCreateComment };

const enhance = compose(
    reduxForm({ form: 'reply' }),
    connect(
        null,
        mapDispatchToProps
    )
);

const CommentReplyFormContainer = enhance(CommentReplyForm);

export default CommentReplyFormContainer;
