import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../util/withAuth';
import { attemptResetPassword } from '../../actions/auth';

import ResetPasswordForm from './Component';

const mapStateToProps = state => ({
    loading: state.auth.loading
});

const mapDispatchToProps = { attemptResetPassword };

const enhance = compose(
    reduxForm({ form: 'reset' }),
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

const ResetPasswordContainer = enhance(ResetPasswordForm);

export default ResetPasswordContainer;
