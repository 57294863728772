import React from 'react';
import styled from 'styled-components/macro';
import SidebarCategoryListItem from './Item';
import SidebarCategoryListHeader from './Header';
import { useSelector } from 'react-redux';
import NavLink from '../../shared/NavLink';

const CategoryList = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Item = styled.button`

  padding: 12px;
  font-size: 13px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #ffffff;
  text-transform: capitalize;
  border:0;
  border-radius: 30px;
  padding: 5px; 
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: 12px;
  background-color: #1890ff;
  color: #fff;
 
  &:hover{
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
    color: #0a58ca;
  }
  
  ::after {
    left: -1px;
    top: 0;
    bottom: 0;
    border-left: 3px solid ${props => props.theme.accent};
  }
`;


const mapCategories = categories =>
  categories.map((category, index) => (
    category.type === "featured" &&
    <SidebarCategoryListItem key={index} category={category} />
  ));


const SidebarCategoryList = () => {

  const categories = useSelector(state => state.categories.featuredItems)
  return (
    <CategoryList>

      <SidebarCategoryListHeader />
      {mapCategories([...categories])}
      <NavLink to="/communities">
        <Item>Browse more communities</Item>
      </NavLink>

    </CategoryList>
  )
};

export default SidebarCategoryList;
