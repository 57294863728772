import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../util/withAuth';
import { attemptUpdateEmail } from '../../actions/auth';
import UpdateEmailForm from './Compnonent';

const mapStateToProps = state => ({
    loading: state.auth.loading,
    user: state.auth.user,

});

const mapDispatchToProps = { attemptUpdateEmail };

let UpdateEmailContainer = UpdateEmailForm;

UpdateEmailContainer = compose(
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UpdateEmailContainer);

UpdateEmailContainer = reduxForm({
    form: 'updateemail',
    enableReinitialize: true,
    immutableProps: ['initialValues']
})(UpdateEmailContainer)

UpdateEmailContainer = connect(
    state => ({
        initialValues: {
            email: state.auth.user.email
        }
    })
)(UpdateEmailContainer)

export default compose(UpdateEmailContainer)