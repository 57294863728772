import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import {
  titleValidator,
  linkPostValidator,
  imagePostValidator,
  videoPostValidator,
  textPostValidator,
  typeValidator
} from '../../util/validators';
import { attemptCreatePost } from '../../actions/posts';
import withAuth from '../../util/withAuth';
import CreatePostForm from './Component';
import { fetchCategories } from '../../actions/categories';


const validate = fields => {
  const errors = {};
  const title = fields.title ? fields.title : '';
  const url = fields.url ? fields.url : '';
  const type = fields.type ? fields.type : '';
  const text = fields.text ? fields.text : '';


  errors.title = titleValidator(title);
  if (type === 'link') errors.url = linkPostValidator(url);
  if (type === 'image') errors.url = imagePostValidator(url);
  if (type === 'video') errors.url = videoPostValidator(url);
  if (type === 'text') errors.text = textPostValidator(text);
  errors.type = typeValidator(type);

  return errors;
};

const mapStateToProps = state => ({
  isFetching: state.posts.isFetching,
  post: state.posts.newPost,
  form: state.form.createPost
});

const mapDispatchToProps = { attemptCreatePost, fetchCategories };

// let categories = useSelector(state => state.categories.items)

const enhance = compose(
  reduxForm({
    form: 'createPost',
    initialValues: { category: "", type: 'link' },
    validate
  }),
  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const CreatePostFormContainer = enhance(CreatePostForm);

export default CreatePostFormContainer;
