import { LOGIN_SUCCESS, SIGNUP_SUCCESS, LOGOUT, UPDATE_EMAIL_SUCCESS, CHANGE_COUNTRY_SUCCESS, EMAIL_VERIFY_SUCCESS } from '../actions/auth';

export default () => next => action => {

  if (action.type === LOGIN_SUCCESS || action.type === SIGNUP_SUCCESS || action.type === UPDATE_EMAIL_SUCCESS || action.type === CHANGE_COUNTRY_SUCCESS || action.type === EMAIL_VERIFY_SUCCESS
  ) {
    localStorage.setItem('token', action.token);
  } else if (action.type === LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem('remember');
  }
  next(action);
};
