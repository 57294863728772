import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux'
import SelectWrapper from '../shared/form/SelectWrapper';

const Dropdown = styled.select`
  border: none;
  border-radius: 0;
  width: 100%;
  text-transform: capitalize;
  padding: 8px 16px;
  background-color: ${props => props.theme.foreground};
  font-size: 15px;
  color: ${props => props.theme.normalText};
  appearance: none;
`;


const CategoryMenuDropdown = (props) => {

  const categories = useSelector(state => state.categories.featuredItems)

  const mapCategories = () =>
    [{ title: 'all', slug: "all" }, ...categories].map((category, index) => (
      <option key={index} value={category.slug}>
        {category.title}
      </option>
    ));

  const handleOnChange = event => {
    const category = event.target.value;
    if (category !== props.category.slug) {
      const url = category === 'all' ? '/' : `/p/${category}`;
      props.history.push(url);
    }
  };

  return (
    <SelectWrapper flex>
      <Dropdown value={props.category} onChange={handleOnChange}>
        {mapCategories()}
      </Dropdown>
    </SelectWrapper>
  );
}


export default CategoryMenuDropdown;
