import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { wideFont } from '../shared/helpers';
import { apiFetchCommunityBySlug } from '../../util/api';
const Wrapper = styled.nav`

  ${wideFont}; 
  display: flex;
  flex-direction:column;
  padding: 10px 10px 10px 15px;
  margin-bottom: 10px;
  background-color: ${props => props.theme.foreground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px; 

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

`;

const HeaderText = styled.h6`

color: ${props => props.theme.normalText};
text-transform:capitalize;
padding-left:10px;
margin-bottom:0;
line-height:27px;
flex-grow:1;
font-size: 21px;
margin-top: 1px;

`;

const HeaderDesc = styled.span`

color: ${props => props.theme.normalText};
text-transform:capitalize;
padding-left:5px;
margin-bottom:0;
line-height:27px;
flex-grow:1;
font-size: 18px;

`;


const InnerWrapper = styled.div`
 display:flex;
 justify-content:space-between;
`
const DescText = styled.p`

color: ${props => props.theme.normalText};
text-transform: none;
font-weight: normal;
padding: 5px 5px 0 0;  
margin-bottom: 0;
font-size:14px;
`
const Subscribe = styled.button`

background: #1890ff;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:3px 0;
    &:hover{
      -webkit-filter: brightness(110%);
      filter: brightness(110%);
      color: #0a58ca;
    }
`

const UnSubscribe = styled.button`

background: #f5222d;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:3px 0;
    &:hover{
      -webkit-filter: brightness(110%);
      filter: brightness(110%); 
    }
`

const Featured = styled.span`
  background: #1890ff;
  border-radius: 5px; 
  border: 0;
  color: #fff;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400; 
  min-width: 68px; 
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align:center;
  padding:3px 0;
`
const New = styled.span`
  
background: #a9770b;
  border-radius: 5px;
  padding: 6px 0px -1px;
  border: 0;
  color: #fff;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400; 
  min-width: 68px; 
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align:center;
  padding:3px 0;
`


const PostHeader = ({ dark, tag, title, showSubscribe, token, user, category, userCommunities, subScribeUserCommunity, desc }) => {

  const [community, setCommunity] = useState(false)

  React.useEffect(() => {
    getDetails()
  }, [category])

  const getDetails = async () => {
    if (showSubscribe) {
      const res = await apiFetchCommunityBySlug({ slug: category });
      setCommunity(res)
    }
  }


  return (


    <Wrapper>

      <InnerWrapper>





        {tag !== undefined && <Featured >Home</Featured>}

        {community.type === "featured" && <Featured > {community.type}</Featured>}

        {community.type === "new" && <New>{community.type}</New>}


        <HeaderText>
          {community.title !== undefined ? community.title : title}{(desc || showSubscribe) && ":"}
          <HeaderDesc>
            {showSubscribe ? community.description : desc}
          </HeaderDesc>
        </HeaderText>

        {showSubscribe && (token && user !== undefined) ?


          userCommunities.includes(community._id) ?

            (<UnSubscribe onClick={() => {
              subScribeUserCommunity({
                communityId: community._id,
              }, token, "unsubscribe")
            }} >Unsubscribe</UnSubscribe>)

            :

            (<Subscribe onClick={() => {
              subScribeUserCommunity({
                communityId: community._id,
              }, token, "subscribe")
            }} >Subscribe</Subscribe>)


          :
          null

        }
      </InnerWrapper>
      {
        (showSubscribe || desc) &&
        <DescText> {showSubscribe ? `A community for ${community.created && timeSince(new Date(community.created))} with ${community.noOfUsers} subscribers and ${community.noOfPosts} posts` : "Home to thousands of communities, endless conversation, and authentic human connection"}</DescText>

      }

    </Wrapper >
  )
}

const intervals = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 }
];

function timeSince(date) {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  // console.log(date, date.getTime())

  const interval = intervals.find(i => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
}



export default PostHeader;
