import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../../util/withAuth';
import { contactUs } from '../../../actions/contact';
import ContactForm from './Component';

const mapStateToProps = state => ({
  loading: state.auth.loading
});

const mapDispatchToProps = { contactUs };

const enhance = compose(
  reduxForm({ form: 'contact' }),
  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const ContactContainer = enhance(ContactForm);

export default ContactContainer;
