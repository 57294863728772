import React from 'react';
import styled from 'styled-components/macro';
// import PostListItem from './Item';
// import LoadingIndicatorBox from '../shared/LoadingIndicator/Box';
import Empty from '../shared/Empty';
import NavLink from '../shared/NavLink'

const Item = styled.li`
	display:flex;
	justify-content:'space-between';
    margin-bottom: 5px;
    background-color: ${props => props.theme.foreground}; 
    border-radius: 2px; 
  :not(:first-child) {
    border-top: 1px solid ${props => props.theme.border};
  }
`;
const NavLinkItem = styled(NavLink)`

    display: block;
    padding: 10px 20px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
	flex-grow:1;
`

const Subscribe = styled.button`

background: #1890ff;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:5px 0;
    margin-right:5px;
`

const UnSubscribe = styled.button`

background: #f5222d;
    border-radius: 30px;
    padding: 6px 0px -1px;
    border: 0;
    color: #fff;
    margin-right:5px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400; 
    min-width: 90px; 
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:5px 0;
`


const List = styled.ul`
  list-style: none; 
  border-radius: 2px;
  padding-left:0px;

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

class Communities extends React.Component {

  componentDidMount() {
    this.props.fetchCategories({})
    if (this.props.user && this.props.token) {
      this.props.fetchUserCommunities(this.props.token)
    }
    // this.props.subScribeUserCommunity()
    // this.props.unSubScribeUserCommunity()
  }

  mapPosts = () =>
    this.props.categories.map((category, index) => (
      <Item key={index}>
        <NavLinkItem to={`/p/${category.slug}`}>{category.title}</NavLinkItem>
        {
          this.props.token !== undefined && this.props.user !== undefined ?
            (this.props.userCommunities.includes(category._id)

              ?
              <UnSubscribe onClick={() => {
                this.props.subScribeUserCommunity({
                  communityId: category._id,

                }, this.props.token, "unsubscribe")
              }} >Unsubscribe</UnSubscribe>
              :
              <Subscribe onClick={() => {
                this.props.subScribeUserCommunity({
                  communityId: category._id,

                }, this.props.token, "subscribe")
              }} >Subscribe</Subscribe>)
            :
            null
        }
      </Item>
    ));

  render() {
    // if (this.props.isFetching) return <LoadingIndicatorBox />;
    if (!this.props.categories || this.props.categories.length === 0) return <Empty />;
    return <List>{this.mapPosts()}</List>;

  }
}

export default Communities;
