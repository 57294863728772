const pages = [
    'about',
    'contact',
    'terms',
    'privacy',
    'guideline',
    'press'
];

export default pages;
