import styled from 'styled-components/macro';
import Button from './Button';

const CommentVoteUpvote = styled(Button)`
  margin-right:5px;
  --iconColor: ${props => {
    console.log(props.theme)
    return props.didVote ? props.theme.upvote : props.theme.vote
  }};

  ::after {
    border-top: 2px solid var(--iconColor);
    border-right: 2px solid var(--iconColor);
    top: 3px;
  }
`;

export default CommentVoteUpvote;
