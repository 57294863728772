import styled from 'styled-components/macro';
import { wideFont } from '../../shared/helpers';

const HeaderUsernameText = styled.span`
//   ${wideFont};
  
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:10px;
  font-weight:600;
  color:#f9920b;
`;

export default HeaderUsernameText;
