import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { headerItem } from '../shared/helpers';
import LogoWhite from '../../assets/logo-white.png'
import LogoBlack from '../../assets/logo.png'

const Logo = styled(Link)`
  ${headerItem};

  margin-right: auto;
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.theme.normalText};
  text-decoration: none;

  @media (max-width: 425px) {
    padding: 0 8px 0 16px;
    font-size: 19px;
  }
`;

const HeaderLogo = ({ dark }) => (
  <Logo to='/'>
    {dark ? <img src={LogoWhite} alt="pabble-logo"></img> : <img src={LogoBlack} alt="pabble-logo"></img>}
  </Logo>
);

export default HeaderLogo;
