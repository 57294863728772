const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api'
    : `https://${window.location.hostname}/api`;

const methods = {
  get: async function (endpoint, token = null) {
    const options = {
      method: 'GET',
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();

    if (!response.ok) throw Error(json.message);

    return json;
  },

  post: async function (endpoint, body, token = null) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();
    if (!response.ok) {
      if (response.status === 422) {
        json.errors.forEach(error => {
          throw Error(`${error.param} ${error.msg}`);
        });
      }

      throw Error(json.message);
    }

    return json;
  },

  put: async function (endpoint, body, token = null) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();
    if (!response.ok) {
      if (response.status === 422) {
        json.errors.forEach(error => {
          throw Error(`${error.param} ${error.msg}`);
        });
      }

      throw Error(json.message);
    }

    return json;
  },

  delete: async function (endpoint, token = null) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();

    if (!response.ok) {
      if (response.status === 401) throw Error('unauthorized');
      throw Error(json.message);
    }

    return json;
  }
};

export async function login(username, password) {
  const json = await methods.post('login', { username, password });
  return json.token;
}

export async function signup(email, username, password, country) {
  const json = await methods.post('register', { email, username, password, country });
  return json.token;
}
export async function forgot(email) {
  const json = await methods.post('forgot', { email });
  return json;
}

export async function resetPassword(email, password) {
  const json = await methods.post('reset-password', { email, password });
  return json;
}
export async function changePassword(data) {
  const json = await methods.post('change-password', data);
  return json;
}

export async function updateEmail(data) {
  const json = await methods.post('update-email', data);
  return json.token;
}

export async function changeCountry(data) {
  const json = await methods.post('change-country', data);
  return json.token;
}

export async function emailVerify(email) {
  const json = await methods.post('email-verify', { email });
  return json.token;
}
export async function resendVerifyEmail(email) {
  const json = await methods.post('resend-email', { email });
  return json;
}

export async function getPosts(category, sort, page = 1) {
  return await methods.get(`posts/${category}${'?page=' + page}${sort && '&sort=' + sort}`);
}
export async function getCategories(data) {
  return await methods.get(`community?type=` + (data.type || "all"));
}
export async function getUserCommunities(token) {
  return await methods.get(`user-communities`, token);
}
export async function apiFetchCommunityBySlug(data) {
  const json = await methods.post(`community-slug`, data);
  return json
}
export async function subScribeCommunity(data, token) {
  return await methods.post(`subscribe-community`, data, token);
}

export async function unSubScribeCommunity(data, token) {
  return await methods.post(`unsubscribe-community`, data, token);
}
export async function getProfile(username) {
  return await methods.get(`user/${username}`);
}

export async function getPost(id) {
  return await methods.get(`post/${id}`);
}

export async function createPost(body, token) {
  return await methods.post('posts', body, token);
}
export async function editPost(body, token) {
  return await methods.put(`post/${body.id}`, body, token);
}

export async function deletePost(id, token) {
  return await methods.delete(`post/${id}`, token);
}

export async function createComment(post, data, token) {
  return await methods.post(`post/${post}`, data, token);
}
export async function deleteComment(post, comment, token) {
  return await methods.delete(`comment/${post}/${comment}`, token);
}

export async function editComment(body, token) {
  return await methods.put(`comment/${body.postId}`, body, token);
}

export async function castVote(id, vote, token) {
  const voteTypes = {
    '1': 'upvote',
    '0': 'unvote',
    '-1': 'downvote'
  };

  const voteType = voteTypes[vote];

  return await methods.get(`post/${id}/${voteType}`, token);
}
export async function castCommentVote(id, vote, token) {
  const commentVoteTypes = {
    '1': 'upvote',
    '0': 'unvote',
    '-1': 'downvote'
  };

  const commentVoteType = commentVoteTypes[vote];

  return await methods.get(`comment/${id}/${commentVoteType}`, token);
}

export async function apiSetPin(body, token) {
  return await methods.put(`post-set-pin/${body.id}`, body, token);
}

export async function apiDeletePin(body, token) {
  return await methods.put(`post-remove-pin/${body.id}`, body, token);
}

export async function contactUs(data) {
  return await methods.post(`contact-us`, data);
}