import jwtDecode from 'jwt-decode';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGOUT,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  FORGOT_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_ERROR,
  EMAIL_VERIFY_REQUEST,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  CHANGE_COUNTRY_REQUEST,
  CHANGE_COUNTRY_SUCCESS,
  CHANGE_COUNTRY_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR


} from '../actions/auth';

const token = localStorage.getItem('token');
const user = token && jwtDecode(token).user;

const initialState = {
  ...(token && { token }),
  ...(user && { user })
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case LOGIN_REQUEST:
    case FORGOT_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case EMAIL_VERIFY_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case UPDATE_EMAIL_REQUEST:
    case CHANGE_COUNTRY_REQUEST:

      return { ...state, loading: true };

    case FORGOT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.token,
        user: {
          ...state.user,
          email: action.email
        }
      };
    // case EMAIL_VERIFY_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false
    //   };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case CHANGE_COUNTRY_SUCCESS:

      return {
        ...state,
        loading: false,
        token: action.token,
        user: {
          ...state.user,
          country: action.country
        }
      };


    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case EMAIL_VERIFY_SUCCESS:
      const user = jwtDecode(action.token).user;
      return {
        ...state,
        loading: false,
        token: action.token,
        user
      };

    case SIGNUP_ERROR:
    case LOGIN_ERROR:
    case FORGOT_ERROR:
    case RESET_PASSWORD_ERROR:
    case EMAIL_VERIFY_ERROR:
    case CHANGE_PASSWORD_ERROR:
    case UPDATE_EMAIL_ERROR:
    case CHANGE_COUNTRY_ERROR:
    case VERIFY_EMAIL_ERROR:
      return { ...state, loading: false };

    case LOGOUT:
      return { ...state, token: null, user: null };
    default:
      return state;
  }
};


