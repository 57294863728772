import React from 'react';
import styled from 'styled-components/macro';
import { link } from './helpers';

const Button = styled.button`
  ${link};
  
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 10px;
  color: ${props => props.theme.mutedText};
  margin-left: 5px;
`;
const Text = styled.text`
  ${link};
  
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  color: ${props => props.theme.replyButtonColor};
  margin-right: 5px;
`;

const ReplyButton = props => (
  <Button onClick={props.onClick}><Text>reply</Text> <i className="fa fa-reply"></i></Button>
);

export default ReplyButton;
