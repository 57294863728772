import React from 'react';
import styled from 'styled-components/macro';
import PostVoteContainer from './Vote/Container';
import PostContent from './Content';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  height: auto;
  background-color: ${props => (props.full === undefined || !props.full) ? (props.pinned ? props.theme.postBg : props.theme.foreground) : props.theme.foreground};
`;
const PostType = styled.div`
padding:8px 0px 8px 10px;
width: 100px;
text-align: center;    
justify-content: center;
display: flex;
max-height:90px;
overflow:hidden;
`;
const PostImg = styled.img`
width: 100%;
height: 67px;
`;
const IconWrapper = styled.div`
    width: 90px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: ${props => props.theme.border};
    color: ${props => props.theme.normalText};
`;

const getType = (type, url, thumbnailUrl) => {

  let defaultImage = <IconWrapper><i className="fa fa-commenting-o" aria-hidden="true"></i></IconWrapper>

  switch (type) {
    case 'video':
      // case 'image':
      return thumbnailUrl ? <PostImg src={thumbnailUrl} alt="" /> : defaultImage
    case 'image':
      return thumbnailUrl ? <PostImg src={thumbnailUrl} alt="" /> : defaultImage
    case 'text':
      return <IconWrapper className=""><i className="fa fa-commenting-o" aria-hidden="true"></i></IconWrapper>
    case 'link':
      return thumbnailUrl ? <PostImg src={thumbnailUrl} alt="" /> : <IconWrapper className=""><i className="fa fa-link" aria-hidden="true"></i></IconWrapper>

    default:
      return defaultImage
  }
}

const Post = ({ id, votes, pinnedHome, pinnedCommunity, score, comments, full, url, thumbnailUrl, community, type, ...content }) => {

  return (
    <Wrapper pinned={community ? pinnedCommunity : pinnedHome} full={full}>
      <PostVoteContainer id={id} votes={votes} score={score} />
      {!full &&
        <PostType>
          {getType(type, url, thumbnailUrl)}
        </PostType>
      }
      <PostContent
        showFullPost={full}
        id={id}
        commentCount={comments ? comments.length : 0}
        url={url}
        thumbnailUrl={
          thumbnailUrl
        }
        type={type}
        community={community}
        pinned={community ? pinnedCommunity : pinnedHome}
        {...content}

      />

    </Wrapper>


  )
}

export default Post;
