import {
  FETCH_POSTS_ERROR,
  FETCH_POST_ERROR,
  CREATE_POST_ERROR,
  DELETE_POST_ERROR,
  CREATE_COMMENT_ERROR,
  DELETE_COMMENT_ERROR,
  VOTE_ERROR
} from '../actions/posts';
import {
  LOGIN_ERROR,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  LOGOUT,
  CHANGE_PASSWORD_ERROR,
  VERIFY_EMAIL_ERROR
} from '../actions/auth';

import { FETCH_USER_COMMUNITIES } from '../actions/categories'
import { hideErrorClearTimeout, showErrorWithTimeout } from '../actions/error';

export default store => next => action => {
  next(action);
  switch (action.type) {

    case LOGOUT:
      if (store.getState().error) store.dispatch(hideErrorClearTimeout());
      break;

    case SIGNUP_SUCCESS:
      if (store.getState().error) store.dispatch(hideErrorClearTimeout());
      break;

    case FETCH_POSTS_ERROR:
    case FETCH_POST_ERROR:
    case CREATE_POST_ERROR:
    case DELETE_POST_ERROR:
    case CREATE_COMMENT_ERROR:
    case DELETE_COMMENT_ERROR:
    case VOTE_ERROR:
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
    case CHANGE_PASSWORD_ERROR:
    case FETCH_USER_COMMUNITIES:
    case VERIFY_EMAIL_ERROR:
      if (action.error) {

        store.dispatch(showErrorWithTimeout(action.error));

      }
      break;

    default:
      break;
  }
};
