import React from 'react';
import styled from 'styled-components';
import CheckBoxOption from './Option';

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  
  input[type=checkbox] {
    // display: none;
  }
`;

function handleClick(e, value, fn) {
  e.preventDefault();
  fn(value);
}

const renderOptions = field => {
  return <CheckBoxOption
    {...field}
    active={field.input.value === field.value}
    onClick={e => handleClick(e, field.value, field.input.onChange)}
  />
};

const CheckBox = ({ field }) => (
  <CheckBoxWrapper>{renderOptions(field)}</CheckBoxWrapper>
);

export default CheckBox;
