import Terms from './Terms'
import Contact from './Contact/Container'
import Press from './Press'
import Privacy from './Privacy'
import About from './About'
import Guideline from './Guideline'

export default {
    Terms,
    Contact,
    Press,
    About,
    Privacy,
    Guideline
}
