const profileitems = [
    'My Posts',
    'My Communities',
    'Change Password',
    'Update Email Address',
    'Change country'


];

export default profileitems;

