import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import withAuth from '../../util/withAuth';
import { attemptForgot } from '../../actions/auth';
import ForgotPasswordForm from './Component';

const mapStateToProps = state => ({
    loading: state.auth.loading
});

const mapDispatchToProps = { attemptForgot };

const enhance = compose(
    reduxForm({ form: 'forgot' }),
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

const ForgotPasswordContainer = enhance(ForgotPasswordForm);

export default ForgotPasswordContainer;
