import React from 'react';
import { NavLink } from '../shared/NavLink'
import { Field } from 'redux-form';

import styled from 'styled-components/macro';
import Form from '../shared/form/Form';
import renderField from '../shared/form/renderField';
import { usernameValidator, passwordValidator } from '../../util/validators';
import SubmitButton from '../shared/form/SubmitButton';
import queryString from 'query-string'
class LoginForm extends React.Component {
  componentDidMount() {
    this.redirectIfLoggedIn();
    let params = queryString.parse(this.props.location.search)
    if (params.verification !== undefined) {
      this.props.attemptEmailVerify(params.verification)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.redirectIfLoggedIn();
  }

  redirectIfLoggedIn() {
    if (this.props.token) this.props.history.push('/');
  }

  onSubmit = ({ username, password, remember }) => {
    this.props.attemptLogin(username, password, remember);
  };

  render() {
    return (

      <Form
        loading={this.props.loading}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >

        <Field
          name='username'
          label='username'
          type='text'
          component={renderField}
          validate={usernameValidator}
        />
        <Field
          name='password'
          label='password'
          type='password'
          component={renderField}
          validate={passwordValidator}
        />
        


        <LabelWrapper>
          <label htmlFor={"remember"}  ><Field
            name="remember"
            id="remember"
            component="input"
            type="checkbox"
            label="Remember me"

          /> <span>Remember me</span>
          </label>

          <SubmitButton type='submit'>log in</SubmitButton>
        </LabelWrapper>
        <LinkWrapper>
          <NavLink to='/forgot-password' style={{ color: "#818e99" }}>FORGOT PASSWORD?</NavLink><br/>
          <NavLink to='/signup' style={{ color: "#818e99" }}>CREATE ACCOUNT</NavLink>
        </LinkWrapper>
      </Form >
    );
  }
}


const LabelWrapper = styled.div`

display: flex;
justify-content: space-between;
width: 100%;
label{
font-size: 12px;
font-weight: 600; 
display: flex;
margin-bottom: 8px;
text-transform: uppercase;
color: ${props => props.theme.mutedText};
align-items: center;
cursor: pointer;
input{
  margin-right:5px;
}}
`;

const LinkWrapper = styled.div`

width: 100%;
text-align: center;
font-size: 12px;
font-weight: 600; 
text-transform: uppercase;
margin-top: 20px;
align: center;
color: ${props => props.theme.mutedText};
`;

export default LoginForm;
