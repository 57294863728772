import React from 'react';
import styled from 'styled-components/macro';
import HeaderNavLink from '../NavLink';
import HeaderUsernameText from './Text';
import KarmaText from './KarmaText'

const Wrapper = styled(HeaderNavLink)`
  flex-shrink: 1;
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  min-width: 0;
  flex-direction:column;
  justify-content:center;
`;

const HeaderUsername = props => (
  <Wrapper to={`/profile/my-posts`}>
    <HeaderUsernameText>{props.username}</HeaderUsernameText>
    <KarmaText>{props.karma} Karma</KarmaText>
  </Wrapper>
);

export default HeaderUsername;
