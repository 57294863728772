export const checkMaxLength = (value, len) =>
  value && value.length <= len
    ? undefined
    : `must be less than or equal to ${len} characters`;

export const checkMinLength = (value, len) =>
  value && value.length >= len
    ? undefined
    : `must be more than or equal to ${len} characters`;

export const checkValidChars = value =>
  /^[a-zA-Z0-9_-]+$/.test(value) ? undefined : 'contains invalid characters';

export const checkIfTrimmed = value =>
  value.trim() === value ? undefined : 'cannot start or end with whitespace';

export const validUrl = value => {
  try {
    new URL(value);
    return undefined;
  } catch (error) {
    return 'must be a valid url';
  }
};

const max = len => value => checkMaxLength(value, len);
const min = len => value => checkMinLength(value, len);
const validChars = value => checkValidChars(value);
const trimmed = value => checkIfTrimmed(value);

export const required = value => (value ? undefined : 'required');

export const email = value => {

  //eslint-disable-next-line
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(value).toLowerCase()) ? undefined : 'Invalid email'
}

export const youtube = value => {

  const re = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return re.test(String(value).toLowerCase()) ? undefined : 'Invalid Youtube link'
}

export const postType = value =>
  value === 'link' || value === 'image' || value === 'video' || value === 'text'
    ? undefined
    : 'must be link, image, video or text post';

export const emailValidator = [required, email, trimmed];

export const usernameValidator = [required, min(3), max(32), validChars, trimmed];

export const passwordValidator = [required, min(8), max(72)];

export const countryValidator = [required, trimmed];

export const titleValidator = value => required(value) || checkMaxLength(value, 100);

export const textPostValidator = value => required(value) || checkMinLength(value, 4);

export const linkPostValidator = value => required(value) || validUrl(value);

export const imagePostValidator = value => required(value) || validUrl(value);

export const videoPostValidator = value => required(value) || validUrl(value) || youtube(value);

export const typeValidator = value => required(value) || postType(value);


