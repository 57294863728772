import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { link } from './helpers';

const StyledLink = styled(Link)`
  ${link};

  font-weight: 500;
  color: ${props => props.theme.normalText};
  margin-top:7px; 
`;

const StyledLinkAdmin = styled(Link)`
  ${link};

  font-weight: bold;
  color: #1890ff !important;
  margin-top:7px; 

  i{
    color:#1890ff;
  }
`;

const Author = ({ username, admin }) => (

  admin === true ?

    <StyledLinkAdmin to={`/u/${username} `}>{<i className="fa fa-shield" aria-hidden="true"></i>} {username}</StyledLinkAdmin>


    :

    <StyledLink to={`/u/${username} `}>{username}</StyledLink>

);

export default Author;
