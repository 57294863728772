import React from 'react'
import styled from 'styled-components/macro';


import SidebarContainer from '../../Sidebar/Container';
import PageSidebarContainer from '../../PageSidebar/Container';

import { Route } from 'react-router-dom';


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin: 0 5vw;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`;

const LeftSide = styled.main`
  flex: 1;
  min-width: 0;
    height: auto;
   color: ${props => props.theme.normalText};
    padding:20px;

    border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  flex-basis: 240px;
  margin-left: 24px;
  // border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  // background-color: ${props => props.theme.foreground};

  @media (max-width: 768px) {
    display: none;
  }
`;


const Guideline = () => {

  return (
    <Wrapper>
      <LeftSide>

        <h2>Community Guideline</h2><br/>

        <p><strong>What to Submit</strong></p>
        <p>Please remain on-topic based on the community you are looking to make the post to. We have many different communities for different purpose/topic. Please refer to the community name, description and community specific guidelines. Anything that Pabble community members would find interesting and aligns with the specific community is fair to post. If you had to reduce it to a sentence, the answer might be: anything that gratifies one's intellectual curiosity.</p>
        <p>If there is no specific community for the post you're looking to make, please feel free to reach out to us via contact@pabble.co and we will create the community as necessary.</p>
        
        <p><strong>In Submissions</strong></p>
        <p>Please don't do things to make titles stand out, like using uppercase or exclamation points, or saying how great an article is. It's implicit in submitting something that you think it's important.</p>
        <p>Please highlight the original source in the comment as needed. If a post reports on something found on another site, submit the latter.</p>
        <p>If the title includes the name of the site, please take it out, because the site name will be displayed after the link.</p>
        <p>If the title contains a gratuitous number or number + adjective, we'd appreciate it if you'd crop it. E.g. translate "10 Ways To Do X" to "How To Do X," and "14 Amazing Ys" to "Ys." Exception: when the number is meaningful, e.g. "The 5 Platonic Solids."</p>
        <p>Otherwise please use the original title, unless it is misleading or linkbait; don't editorialize.</p>
        <p>Please don't post on Pabble to ask or tell us something. Please reach out to us via contact@pabble.co.</p>
        <p>Please don't delete and repost. Deletion is for things that shouldn't have been submitted in the first place.</p>
        <p>Please don't use Pabble primarily for promotion. It's ok to post your own stuff occasionally, but the primary use of the site should be for curiosity.</p>
        <p>Don't solicit upvotes, comments, or submissions. Users should vote and comment when they run across something they personally find interesting—not for promotion.</p>
        
        <p><strong>In Comments</strong></p>
        <p>Be kind. Don't be snarky. Have curious conversation; don't cross-examine. Please don't fulminate. Please don't sneer, including at the rest of the community.</p>
        <p>Comments should get more thoughtful and substantive, not less, as a topic gets more divisive.</p>
        <p>When disagreeing, please reply to the argument instead of calling names. "That is idiotic; 1 + 1 is 2, not 3" can be shortened to "1 + 1 is 2, not 3."</p>
        <p>Please respond to the strongest plausible interpretation of what someone says, not a weaker one that's easier to criticize. Assume good faith.</p>
        <p>Eschew flamebait. Avoid unrelated controversies and generic tangents.</p>
        <p>Please don't post shallow dismissals, especially of other people's work. A good critical comment teaches us something.</p>
        <p>Please don't use Hacker News for political or ideological battle. It tramples curiosity.</p>
        <p>Please don't comment on whether someone read an article. "Did you even read the article? It mentions that" can be shortened to "The article mentions that."</p>
        <p>Please don't pick the most provocative thing in an article or post to complain about in the thread. Find something interesting to respond to instead.</p>
        <p>Throwaway accounts are ok for sensitive information, but please don't create accounts routinely. Pabble is a community - users should have an identity that others can relate to.</p>
        <p>Please don't use uppercase for emphasis. If you want to emphasize a word or phrase, put *asterisks* around it and it will get italicized.</p>
        <p>Please don't post insinuations about astroturfing, shilling, bots, brigading, foreign agents and the like. It degrades discussion and is usually mistaken. If you're worried about abuse, email contact@pabble.co and we'll look at the data.</p>
        <p>Please don't complain that a submission is inappropriate. If a story is spam or off-topic, flag it. Don't feed egregious comments by replying; flag them instead. If you flag, please don't also comment that you did.</p>
        <p>Please don't complain about tangential annoyances—things like article or website formats, name collisions, or back-button breakage. They're too common to be interesting.</p>
        <p>Please don't comment about the voting on comments. It never does any good, and it makes boring reading.</p>

      </LeftSide>

      <SidebarWrapper>

        <Route component={SidebarContainer} />

        <Route component={PageSidebarContainer} />

      </SidebarWrapper>
    </Wrapper>
  )
};

export default Guideline;
